import React from 'react'

import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'

import Image from '@/components/Image'
import {Enums, EnumsText} from '@/constants'
import {getStatusText} from '@/pages/customer/components/TransactionUtils'

export const columns: ColumnProps<any>[] = [
  {
    title: '依頼日付',
    dataIndex: ['assessmentRequest', 'createdAt'],
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '商品',
    dataIndex: ['item', 'mainImage', 'thumbUrl'],
    render: (url) => <Image src={url} width={60} height={60} inline />,
  },
  {
    title: '出品者',
    dataIndex: ['assessmentRequest', 'sellerLegalEntityName'],
  },
  {
    title: '買取業者',
    dataIndex: ['assessmentRequest', 'buyerLegalEntityName'],
  },
  {
    title: 'ステータス',
    dataIndex: 'status',
    render: (transactionStatus, record) =>
      getStatusText(
        transactionStatus,
        record?.assessmentRequest?.status,
        record?.assessmentReply?.status,
        EnumsText.TransactionStatusEnum.FOR_ADMIN
      ),
  },
  {
    title: '査定期間',
    dataIndex: ['assessmentRequest', 'createdAt'],
    render: (from, record) => {
      const to = Number(record?.assessmentRequest?.oneChanceDeadline) || record?.assessmentRequest?.deadline
      return (
        <div className="d-flex">
          <div style={{display: 'inline-block', marginRight: 30}}>
            {dayjs.unix(from).format('YYYY/MM/DD')}
            <br />
            {dayjs.unix(from).format('HH:mm')}
          </div>
          <div style={{display: 'inline-block'}}>
            {dayjs.unix(to).format('YYYY/MM/DD')}
            <br />
            {dayjs.unix(to).format('HH:mm')}
          </div>
        </div>
      )
    },
  },
  {
    title: '査定金額',
    dataIndex: 'price',
    render: (price, {purchasePrice, flowType}) => {
      let result
      if (flowType === Enums.TransactionFlowTypeEnum.NormalTransaction || purchasePrice === '0') {
        result = Number(price)
      } else {
        result = Number(purchasePrice)
      }
      return result?.toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
    },
  },
  // 通常査定の場合: price - sellerFee
  // 現物査定の場合：purchease_price - sellerPurchaseFee
  {
    title: '手数料（売）',
    dataIndex: 'sellerFee',
    render: (sellerFee, {sellerPurchaseFee, flowType}) => {
      let result
      if (flowType === Enums.TransactionFlowTypeEnum.NormalTransaction || sellerPurchaseFee === '0') {
        result = Number(sellerFee)
      } else {
        result = Number(sellerPurchaseFee)
      }
      return result?.toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
    },
  },
  {
    title: '手数料（買）',
    dataIndex: 'fee',
    render: (fee, {purchaseFee, flowType}) => {
      let result
      if (flowType === Enums.TransactionFlowTypeEnum.NormalTransaction || purchaseFee === '0') {
        result = Number(fee)
      } else {
        result = Number(purchaseFee)
      }
      return result?.toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
    },
  },
]
