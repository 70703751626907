import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'
import {history} from '@/routes'
import {AccountActions} from '@/actions'
import {AccountConstants} from '@/store/constants'

import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const dataSource = useSelector((state) => _.get(state, ['account', 'userList'], []))

  const getData = () => dispatch(AccountActions.listAccounts())
  const onRow = useCallback((record) => ({onClick: () => history.push(`/users/details/${record.id}`)}), [])

  return (
    <Table
      onRow={onRow}
      columns={columns}
      dataSource={dataSource}
      rowKey="id"
      actionType={AccountConstants.LIST_ACCOUNTS}
      onChange={getData}
    />
  )
}
