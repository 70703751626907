import React from 'react'
import {Button} from 'antd'
import {Link} from 'react-router-dom'
import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'

import Image from '@/components/Image'
import {Enums} from '@/constants'

export const columns: (
  withdrawal: (id: string, version: string) => () => void,
  buttonLoading?: boolean
) => ColumnProps<any>[] = (withdrawal, buttonLoading) => [
  {
    title: '確認依頼日時',
    dataIndex: 'updatedAt',
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '商品',
    dataIndex: ['item', 'mainImage', 'thumbUrl'],
    render: (url) => <Image src={url} width={60} height={60} inline />,
  },
  {
    title: '出品業社',
    dataIndex: ['assessmentRequest', 'sellerLegalEntityName'],
    render: (sellerLegalEntityName, record) => (
      <Link to={`/users/details/${record?.assessmentRequest?.sellerUserId}`}>{sellerLegalEntityName}</Link>
    ),
  },
  {
    // 通常査定の場合: price - sellerFee
    // 現物査定の場合：purchease_price - sellerPurchaseFee
    title: '振込金額',
    dataIndex: 'purchasePrice',
    render: (purchasePrice, {price, sellerFee, sellerPurchaseFee, flowType}) => {
      let result
      if (flowType === Enums.TransactionFlowTypeEnum.NormalTransaction) {
        result = Number(price) - Number(sellerFee)
      } else {
        result = Number(purchasePrice) - Number(sellerPurchaseFee)
      }
      return result?.toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'})
    },
  },
  {
    dataIndex: 'transactionId',
    render: (transactionId, {version}) => (
      <Button
        type="primary"
        style={{marginRight: 4}}
        onClick={withdrawal(transactionId, version)}
        loading={buttonLoading}>
        振込完了
      </Button>
    ),
  },
]
