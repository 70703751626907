import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'

import {AssessmentActions} from '@/actions'
import {AssessmentConstants} from '@/store/constants'

import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const {dataSource, buttonLoading} = useSelector((state) => ({
    dataSource: _.get(state, ['assessment', 'adminHistoriesForWithdrawal'], []),
    buttonLoading: _.get(state, ['loading', AssessmentConstants.WITHDRAWAL]),
  }))

  const getData = () => dispatch(AssessmentActions.listAdminTransactionHistoriesForWithdrawal())

  const withdrawal = useCallback(
    (transactionId: string, version: string) => () => {
      dispatch(AssessmentActions.withdrawal({transactionId, version, onSuccess: getData}))
    },
    []
  )
  return (
    <>
      <Table
        columns={columns(withdrawal, buttonLoading)}
        dataSource={dataSource}
        rowKey={(record) => record.assessmentRequest?.id}
        actionType={AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_WITHDRAWAL}
        onChange={getData}
      />
    </>
  )
}
