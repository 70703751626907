import React, {useCallback} from 'react'
import {Button} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'
import {history} from '@/routes'
import {ContentActions} from '@/actions'
import {ContentConstants} from '@/store/constants'

import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const dataSource = useSelector((state) => _.get(state, ['content', 'informationList'], []))

  const getData = () => dispatch(ContentActions.listInformation())
  const onRow = useCallback((record) => ({onClick: () => history.push(`/informations/detail/${record.id}`)}), [])
  const onClick = () => history.push(`/informations/create`)
  return (
    <>
      <div style={{marginBottom: 20, textAlign: 'right'}}>
        <Button type="ghost" onClick={onClick}>
          新規登録
        </Button>
      </div>
      <Table
        onRow={onRow}
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        actionType={ContentConstants.LIST_INFORMATION}
        onChange={getData}
      />
    </>
  )
}
