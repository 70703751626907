import React from 'react'

import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'
import {Button} from 'antd'
import {Link} from 'react-router-dom'

import Image from '@/components/Image'

export const columns: (
  refund: (id: string, refundAmount: string, version: string) => () => void,
  buttonLoading?: boolean
) => ColumnProps<any>[] = (refund, buttonLoading) => [
  {
    title: '取引完了日時',
    dataIndex: 'updatedAt',
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '商品画像',
    dataIndex: 'itemImageUrl',
    render: (url) => (url ? <Image src={url} width={60} height={60} inline /> : '--'),
  },
  {
    title: '買取業社',
    dataIndex: 'legalEntityId',
    render: (legalEntityId, {userId}) => <Link to={`/users/details/${userId}`}>{legalEntityId}</Link>,
  },
  {
    title: '返金金額',
    dataIndex: 'amount',
    render: (text) => Number(text).toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'}),
  },
  {
    dataIndex: 'orderId',
    render: (orderId, {amount: refundAmount, version}) => (
      <Button
        type="primary"
        style={{marginRight: 4}}
        onClick={refund(orderId, refundAmount, version)}
        loading={buttonLoading}>
        振込み完了ボタン
      </Button>
    ),
  },
]
