import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'
import {history} from '@/routes'
import {AssessmentActions} from '@/actions'
import {AssessmentConstants} from '@/store/constants'

import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const dataSource = useSelector((state) => _.get(state, ['assessment', 'adminHistories'], []))

  const getData = () => dispatch(AssessmentActions.listAdminTransactionHistories({}))
  const onRow = useCallback(
    (record) => ({onClick: () => record.status && history.push(`/transactions/${record.assessmentRequest?.id}`)}),
    []
  )

  return (
    <Table
      onRow={onRow}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.assessmentRequest?.id}
      actionType={AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES}
      onChange={getData}
      rowClassName={(record) => record.status || 'cursor-default'}
    />
  )
}
