import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'

import {AssessmentActions} from '@/actions'
import {AssessmentConstants} from '@/store/constants'
import {history} from '@/routes'

import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const {dataSource} = useSelector((state) => ({
    dataSource: _.get(state, ['assessment', 'adminHistoriesForPlatformDeliveryToSeller'], []),
  }))

  const getData = () => dispatch(AssessmentActions.listAdminTransactionHistoriesForPlatformDeliveryToSeller())

  const onTapTrackNumber = useCallback(
    (record) => () => {
      history.push(`platformDeliveryInputToSeller/${record.transactionId}/${record.version}`)
    },
    []
  )

  return (
    <>
      <Table
        columns={columns(onTapTrackNumber)}
        dataSource={dataSource}
        rowKey={(record) => record.assessmentRequest?.id}
        actionType={AssessmentConstants.LIST_ADMIN_TRANSACTION_HISTORIES_FOR_PLATFORM_DELIVERY_TO_SELLER}
        onChange={getData}
      />
    </>
  )
}
