import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'

import {OrderActions, PaymentActions} from '@/actions'
import {OrderConstants, PaymentConstants} from '@/store/constants'

import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const {dataSource, buttonLoading} = useSelector((state) => ({
    dataSource: _.get(state, ['payment', 'list_awaiting_refund'], []),
    buttonLoading: _.get(state, ['loading', OrderConstants.REFUND_ORDER]),
  }))

  const getData = () => dispatch(PaymentActions.listAwaitingRefundData())

  const refund = useCallback(
    (orderId: string, refundAmount: string, version: string) => () => {
      dispatch(OrderActions.refundOrder({orderId, refundAmount, version, onSuccess: getData}))
    },
    []
  )
  return (
    <>
      <Table
        columns={columns(refund, buttonLoading)}
        dataSource={dataSource}
        rowKey="id"
        actionType={PaymentConstants.LIST_AWAITING_REFUND_DATA}
        onChange={getData}
      />
    </>
  )
}
