import RegisterDetails from '@/pages/admin/registration/details'
import Registration from '@/pages/admin/registration'
import Users from '@/pages/admin/users'
import UserDetails from '@/pages/admin/users/details'
import Informations from '@/pages/admin/informations'
import InformationDetails from '@/pages/admin/informations/details'
import InformationCreate from '@/pages/admin/informations/create'
import Transactions from '@/pages/admin/transactions'
import TransactionDetails from '@/pages/admin/transactions/details'
import ConfirmPaid from '@/pages/admin/confirmPaid'
import Withdrawal from '@/pages/admin/withdrawal'
import Refund from '@/pages/admin/refund'
import PlatformDeliveryToSeller from '@/pages/admin/platformDeliveryToSeller'
import PlatformDeliveryToBuyer from '@/pages/admin/platformDeliveryToBuyer'
import PlatformDeliveryInputToBuyer from '@/pages/admin/platformDeliveryInputToBuyer'
import PlatformDeliveryInputToSeller from '@/pages/admin/platformDeliveryInputToSeller'

import Password from '@/pages/common/password'
import NotFound from '@/pages/common/exception/notFound'
import Login from '@/pages/common/login'

import AuthWrapper from '@/wrappers/Auth'
import ContentWrapper from '@/wrappers/Content'
import BreadcrumbWrapper from '@/wrappers/Breadcrumb'
import SiderMenuWrapper from '@/wrappers/SiderMenu'
import HeaderWrapper from '@/wrappers/Header'
import LayoutWrapper from '@/wrappers/Layout'

import {IRoutesConfig} from '@/routes'

const accountWrappers = [ContentWrapper, BreadcrumbWrapper, HeaderWrapper, LayoutWrapper]
const accountWrappersWithAuth = [ContentWrapper, BreadcrumbWrapper, HeaderWrapper, LayoutWrapper, AuthWrapper]

const routes: IRoutesConfig[] = [
  {path: '/', redirect: '/registration', exact: true},
  {path: '/account/password', redirect: '/account/password/reset', exact: true},

  {path: '/notFound', component: NotFound, wrappers: [HeaderWrapper, LayoutWrapper]},
  {path: '/login', component: Login, wrappers: [HeaderWrapper, LayoutWrapper]},

  {path: '/account/password/reset', wrappers: accountWrappers, component: Password},
  {path: '/account/password/change', wrappers: accountWrappers, component: Password},
  {
    path: '/account',
    wrappers: accountWrappersWithAuth,
    routes: [{path: '*', redirect: '/notFound'}],
  },

  {
    path: '/',
    wrappers: [ContentWrapper, BreadcrumbWrapper, SiderMenuWrapper, HeaderWrapper, LayoutWrapper, AuthWrapper],
    routes: [
      {path: '/registration/details/:id', component: RegisterDetails},
      {path: '/registration', component: Registration},
      {path: '/users/details/:id', component: UserDetails},
      {path: '/users', component: Users},
      {path: '/informations/detail/:id', component: InformationDetails},
      {path: '/informations/create', component: InformationCreate},
      {path: '/informations', component: Informations},
      {path: '/transactions/:id', component: TransactionDetails},
      {path: '/transactions', component: Transactions},
      {path: '/confirmPaid', component: ConfirmPaid},
      {path: '/withdrawal', component: Withdrawal},
      {path: '/refund', component: Refund},
      {path: '/platformDeliveryToBuyer', component: PlatformDeliveryToBuyer},
      {path: '/platformDeliveryToSeller', component: PlatformDeliveryToSeller},
      {
        path: '/platformDeliveryInputToBuyer/:transactionId/:version',
        component: PlatformDeliveryInputToBuyer,
      },
      {
        path: '/platformDeliveryInputToSeller/:transactionId/:version',
        component: PlatformDeliveryInputToSeller,
      },
      {path: '*', redirect: '/notFound'},
    ],
  },
]

export default routes
