import React from 'react'
import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'
import {getEnumTextByEnum} from '@/utils'
import {Enums, EnumsText} from '@/constants'
import {Tooltip} from 'antd'

const noTime = ''

const calcPostingStatus = (startAt: number, endAt: number) => {
  if (dayjs().isBefore(dayjs.unix(startAt))) {
    return Enums.PostingStatusEnum.BeforePosting
  }
  if (dayjs().isAfter(dayjs.unix(endAt))) {
    return Enums.PostingStatusEnum.Termination
  }
  return Enums.PostingStatusEnum.Posting
}

export const columns: ColumnProps<any>[] = [
  {
    title: '日付',
    dataIndex: 'createdAt',
    width: 160,
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: 'お知らせ',
    dataIndex: 'informationTitle',
    ellipsis: {showTitle: false},
    render: (text) => (
      <Tooltip title={text} placement="topLeft">
        <span>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: '表示ステータス',
    dataIndex: 'informationStaus',
    render: (text, {startAt, endAt}) =>
      getEnumTextByEnum(calcPostingStatus(startAt, endAt), EnumsText.PostingStatusEnum),
  },
  {
    title: '表示タイプ',
    dataIndex: 'informationType',
    render: (text) => getEnumTextByEnum(text, EnumsText.InformationTypeEnum),
  },
  {
    title: '表示開始日時',
    dataIndex: 'startAt',
    render: (text) =>
      Number(text) ? (
        <>
          {dayjs.unix(text).format('YYYY/MM/DD')}
          <br />
          {dayjs.unix(text).format('HH:mm:ss')}
        </>
      ) : (
        noTime
      ),
  },
  {
    title: '表示終了日時',
    dataIndex: 'endAt',
    render: (text) =>
      Number(text) ? (
        <>
          {dayjs.unix(text).format('YYYY/MM/DD')}
          <br />
          {dayjs.unix(text).format('HH:mm:ss')}
        </>
      ) : (
        noTime
      ),
  },
]
