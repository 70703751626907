import React from 'react'
import {ColumnProps} from 'antd/lib/table'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'

export const columns: ColumnProps<any>[] = [
  {
    title: '申請日時',
    dataIndex: 'createdAt',
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '事業所名',
    dataIndex: 'legalEntityName',
    render: (text) => <Link to="/">{text}</Link>,
  },
  {
    title: '申請者名',
    dataIndex: 'familyName',
    render: (__, record) => record.familyName + record.givenName,
  },
  {
    title: 'メールアドレス',
    dataIndex: 'email',
  },
]
