import React from 'react'
import {Link} from 'react-router-dom'
import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'
import {Button} from 'antd'

import Image from '@/components/Image'
import {getEnumTextByEnum} from '@/utils'
import {EnumsText} from '@/constants'

export const columns: (
  confirmPaid: (id: string, version: string) => () => void,
  buttonLoading?: boolean
) => ColumnProps<any>[] = (confirmPaid, buttonLoading) => [
  {
    title: '確認依頼日時',
    dataIndex: 'updatedAt',
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: '商品画像',
    dataIndex: 'itemImageUrl',
    render: (url) => (url ? <Image src={url} width={60} height={60} inline /> : '--'),
  },
  {
    title: '買取業社',
    dataIndex: 'legalEntityId',
    render: (legalEntityId, {userId}) => <Link to={`/users/details/${userId}`}>{legalEntityId}</Link>,
  },
  {
    title: '決済方法',
    dataIndex: 'paymentMethod',
    render: (text) => getEnumTextByEnum(text, EnumsText.PaymentMethodEnum),
  },
  {
    title: '入金金額',
    dataIndex: 'amount',
    render: (text) => Number(text).toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'}),
  },
  {
    dataIndex: 'orderId',
    render: (orderId, {version}) => (
      <Button type="primary" style={{marginRight: 4}} onClick={confirmPaid(orderId, version)} loading={buttonLoading}>
        入金確認
      </Button>
    ),
  },
]
