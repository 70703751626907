import React, {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'

import Table from '@/components/Table'

import {PaymentActions, OtherActions} from '@/actions'
import {PaymentConstants, OtherConstants} from '@/store/constants'

import {columns} from './colConfig'

export default () => {
  const dispatch = useDispatch()
  const {dataSource, buttonLoading} = useSelector((state) => ({
    dataSource: _.get(state, ['payment', 'list_awaiting_confirm_payment'], []),
    buttonLoading: _.get(state, ['loading', OtherConstants.PAY_ORDER_CONFIRM]),
  }))

  const getData = () => dispatch(PaymentActions.listAwaitingConfirmPaymentData())

  const confirmPaid = useCallback(
    (orderId: string, version: string) => () => {
      dispatch(OtherActions.payOrderConfirm({orderId, version, onSuccess: getData}))
    },
    []
  )

  return (
    <>
      <Table
        columns={columns(confirmPaid, buttonLoading)}
        dataSource={dataSource}
        rowKey="id"
        actionType={PaymentConstants.LIST_AWAITING_CONFIRM_PAYMENT_DATA}
        onChange={getData}
      />
    </>
  )
}
