import React from 'react'
import {ColumnProps} from 'antd/lib/table'
import dayjs from 'dayjs'
import {getEnumTextByEnum} from '@/utils'
import {EnumsText} from '@/constants'

export const columns: ColumnProps<any>[] = [
  {
    title: 'アプリ表示番号',
    dataIndex: 'displayId',
  },
  {
    title: '業者名',
    dataIndex: 'IsIndividual',
    render: (text, record) => (text ? record.familyName + record.givenName : record.legalEntityName),
  },
  {
    title: '申請者氏名',
    dataIndex: 'familyName',
    render: (__, record) => record.familyName + record.givenName,
  },
  {
    title: 'メールアドレス',
    dataIndex: 'email',
  },
  {
    title: '申請日付',
    dataIndex: 'createdAt',
    render: (text) => (
      <>
        {dayjs.unix(text).format('YYYY/MM/DD')}
        <br />
        {dayjs.unix(text).format('HH:mm')}
      </>
    ),
  },
  {
    title: 'ステータス',
    dataIndex: 'accountStatus',
    render: (text) => getEnumTextByEnum(text, EnumsText.AccountStatusEnum),
  },
  {
    title: '利用プラン',
    dataIndex: 'planName',
  },
]
